<template>
  <div>
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-sider
        v-model="collapsed"
        :trigger="null"
        collapsible
        style="
          background: #fff;
          box-shadow: 1px 0px 4px rgba(0, 21, 41, 0.12);
          z-index: 999;
        "
        width="208px"
      >
        <div class="logo d-f a-c j-c">
          <img :src="tenantLogo" width="32px" v-if="tenantLogo" />
          <img src="@/assets/images/layout_logo.png" width="32px" v-else />
          <span v-if="!collapsed" class="title">{{ title }}</span>
        </div>
        <a-menu mode="inline" v-model="selectedKeys" :default-open-keys="defaultOpenKeys">
          <template v-for="item in layoutList">
            <a-sub-menu
              v-if="item.component == null && item.isFrame === 1"
              :key="item.path"
            >
              <span slot="title">
                <span class="anticon" v-if="item.icon && item.icon != ''">
                  <img :src="item.icon" width="16px" style="margin-top: -7px" />
                </span>
                <span>{{ item.menuName }}</span>
              </span>
              <a-menu-item :key="rr.path" v-for="rr in item.children">
                <router-link :to="rr.path">{{ rr.menuName }}</router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item
              :key="item.path"
              v-if="item.component != null && item.isFrame === 1"
            >
              <router-link :to="item.path">
                <span class="anticon" v-if="item.icon && item.icon != ''">
                  <img :src="item.icon" width="16px" style="margin-top: -7px" />
                </span>
                <span>{{ item.menuName }}</span>
              </router-link>
            </a-menu-item>
            <a-menu-item :key="item.path" v-if="item.isFrame === 0">
              <a :href="item.path" target="_blank">
                <span class="anticon" v-if="item.icon && item.icon != ''">
                  <img :src="item.icon" width="16px" style="margin-top: -7px" />
                </span>
                <span>{{ item.menuName }}</span>
              </a>
            </a-menu-item>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header
          style="
            background: #fff;
            padding: 0 24px;
            box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
            z-index: 99;
          "
          class="d-f a-c j-b"
        >
          <div class="d-f a-c">
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (collapsed = !collapsed)"
            />
            <img src="@/assets/images/layout_location.png" width="20px" />
            <span class="school">{{ location }}</span>
          </div>
          <div class="d-f a-c">
            <a-tooltip placement="bottom">
              <template slot="title">
                <span>返回主菜单</span>
              </template>
              <img
                src="@/assets/images/layout_home.png"
                width="20px"
                @click="toEnter"
                class="mr24"
              />
            </a-tooltip>
            <a-tooltip placement="bottom">
              <template slot="title">
                <span>消息通知</span>
              </template>
              <a-badge :count="count">
                <img
                  src="@/assets/images/layout_bell.png"
                  width="20px"
                  @click="toMessageNotification"
                />
              </a-badge>
            </a-tooltip>
            <span class="role">{{ role }}</span>
            <a-dropdown placement="bottomCenter">
              <a-menu slot="overlay" @click="handleMenuClick">
                <a-menu-item key="1">个人中心</a-menu-item>
                <a-menu-item key="2">退出</a-menu-item>
              </a-menu>
              <div class="d-f a-c">
                <img
                  :src="avatarUrl"
                  width="32px"
                  height="32px"
                  style="border-radius: 50%"
                />
                <p class="username">{{ username }}</p>
              </div>
            </a-dropdown>
          </div>
        </a-layout-header>
        <a-layout-content>
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
let avatar = require("@/assets/images/enter_avatar.png");
import { getLayoutList } from "@/api/acl/menu.js";
import { getSchoolNameBySchoolNum } from "@/api/basic-info/school.js";
import { getMessageNotificationCount } from "@/api/user-center/message-notification.js";
import { checkToken } from "@/api/login";
import { setCookie } from "@/utils/cookie.js";
export default {
  data() {
    return {
      collapsed: false,
      layoutList: [],
      //消息通知的数量
      count: 0,
      location: "",
      selectedKeys: [],
    };
  },
  methods: {
    handleMenuClick(e) {
      if (e.key == "1") {
        this.$router.push("/user-center");
      } else {
        //退出登录的接口
        this.$confirm({
          title: "您确认退出登录吗？",
          onOk: () => {
            let baseUrl = process.env.VUE_APP_BASEURL;
            let accessToken = this.$ls.get("access_token");
            let refreshToken = this.$ls.get("refresh_token");
            let userId = this.$ls.get("userInfo").id;
            let hostname = location.origin;
            //判断token有没有失效
            checkToken({ token: this.$ls.get("access_token") }).then((response) => {
              if (response.active) {
                window.location = `${baseUrl}/api-uaa/oauth/remove/token?access_token=${accessToken}&refresh_token=${refreshToken}&userId=${userId}&redirect_uri=${hostname}/login`;

                setCookie("access_token", null);
                setCookie("token_type", null);
                setCookie("refresh_token", null);
                setCookie("scope", null);
                this.$ls.set("access_token", null);
                this.$ls.set("token_type", null);
                this.$ls.set("refresh_token", null);
                this.$ls.set("scope", null);
              }
            });
          },
        });
      }
    },
    toMessageNotification() {
      this.$router.push("/user-center/message-notification");
    },
    toEnter() {
      this.$router.push("/enter");
    },
    getLayoutList() {
      getLayoutList({ parentId: this.$ls.get("parentId") }).then((res) => {
        this.layoutList = res;
      });
    },
    //获取消息通知数量
    getMessageNotificationCount() {
      getMessageNotificationCount().then((res) => {
        this.count = res.data;
      });
    },
    //根据schoolNum或者学校名称
    getSchoolNameBySchoolNum() {
      if (this.$ls.get("userInfo").schoolNum) {
        getSchoolNameBySchoolNum(this.$ls.get("userInfo").schoolNum).then((res) => {
          this.location = res.name;
        });
      } else {
        this.location = "";
      }
    },
  },
  created() {
    this.getLayoutList();
    this.getMessageNotificationCount();
    this.getSchoolNameBySchoolNum();
    this.selectedKeys = [this.$route.path];
  },
  computed: {
    //默认展开的侧边栏
    defaultOpenKeys() {
      return [this.$route.matched[0].path];
    },
    //项目标题
    title() {
      if (this.$ls.get("userInfo").tenantName) {
        return this.$ls.get("userInfo").tenantName + "校园管理平台";
      } else {
        return "智慧校园管理平台";
      }
    },
    //头像地址
    avatarUrl() {
      if (this.$ls.get("userInfo").headImgUrl) {
        return this.$ls.get("userInfo").headImgUrl;
      } else {
        return avatar;
      }
    },
    //角色
    role() {
      let roleList = this.$ls.get("userInfo").roles;
      let roles = "";
      roleList.forEach((item) => {
        if (roles != "") {
          roles += "," + item.name;
        } else {
          roles += item.name;
        }
      });
      return roles;
    },
    //用户名
    username() {
      return this.$ls.get("userInfo").username;
    },
    //租户logo
    tenantLogo() {
      return this.$ls.get("userInfo").tenantLogo;
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.selectedKeys = [val.path];
        this.$forceUpdate();
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
#components-layout-demo-custom-trigger {
  // min-width: 1440px;
  // overflow-x: auto;
  min-height: 100vh;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  cursor: pointer;
  margin-right: 24px;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger {
  .logo {
    height: 64px;
    .title {
      font-size: 14px;
      font-family: "PingFang SC";
      font-weight: bold;
      margin-left: 16px;
    }
  }
  .school {
    margin-left: 12px;
    font-size: 14px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  .role {
    font-size: 14px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    margin-right: 12px;
    margin-left: 24px;
  }
  .username {
    max-width: 100px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 12px;
    margin-bottom: 0;
  }
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  text-align: center;
}
.ant-dropdown-menu-item-active {
  color: @primary-color;
  background: transparent;
}
</style>
